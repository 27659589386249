<template lang="pug">
v-data-table(
  :headers="headers",
  :items="items",
  :search="search",
  dense,
  mobile-breakpoint="0"
)
  template(v-slot:top)
    .d-flex.flex-row.align-center.px-4
      v-text-field.mr-4(v-model="search", label="Search", clearable)
      CSVDownload(:items="csvData", file-name="product_mix_by_menu")
  template(v-slot:item.amount="{ item }")
    span {{ item.amount | currency }}
</template>

<script>
import CSVDownload from "@/components/basic/CSVDownload";
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  components: { CSVDownload },
  props: {
    dateRange: { type: String, required: true },
    menus: { type: Array, required: true },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Menu", value: "name" },
        { text: "Quantity", value: "quantity" },
        { text: "Total", value: "amount", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    items() {
      return _.chain(this.menus)
        .groupBy("id")
        .map((o) => {
          const quantity = o.reduce((a, b) => a + b.quantity, 0);
          const amount = o.reduce((a, b) => a + b.amount, 0);
          return {
            _id: o[0].id,
            name: o[0].name,
            quantity: quantity,
            amount: amount,
          };
        })
        .sortBy("amount")
        .reverse()
        .value();
    },
    csvData() {
      if (!this.items || !this.items.length || !this.biz) return [];
      const currency = this.$options.filters.currency;
      let data = [this.headers.map((o) => o.text)];
      this.items.forEach((item) => {
        data.push([item.name, item.quantity, currency(item.amount)]);
      });
      data.push([]); // spacing row
      data.push([this.dateRange]);
      data.push([this.biz.name]);
      return data;
    },
  },
  methods: {
    getMenuName(val) {
      return this.biz.menus.find((o) => o._id == val)?.name || "N/A";
    },
  },
};
</script>
