<template lang="pug">
.s-vstack.items-start
  DateRangePicker(
    v-model="range",
    @select="load()",
    :selector="true",
    :loading="loading"
  )
  BizStartHour
  OpsReport(:items="statements")
  v-row.w-full(wrap, justify="center")
    v-col(cols="12", sm="8")
      LaborReport(:sales="net", max-width="100%", ref="laborReport")
    v-col(cols="12", sm="8")
      ProductMixReport(:range="range", :statements="statements")
  .s-vstack.items-center.w-full
    Download(:range="range", :items="statements")
    Notice
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import Download from "./Download";
import Notice from "./Notice";
import OpsReport from "./OpsReport";
import ProductMixReport from "./ProductMixReport/index";

export default {
  components: { ProductMixReport, Download, Notice, OpsReport },
  data() {
    return {
      range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      loading: false,
      statements: [],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    net() {
      return (
        this.statements?.reduce((acc, cur) => acc + (cur.sales?.net || 0), 0) ||
        0
      );
    },
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.biz || !this.biz._id) return;
      this.loading = true;
      try {
        await this.loadOps();
        const params = {
          bizId: this.biz._id,
          beginDate: this.range[0],
          endDate: this.range[1],
        };
        this.$refs.laborReport?.load(params);
      } catch (e) {
        this.$toast.error(e.message);
      }
      this.loading = false;
    },
    async loadOps() {
      let begin = moment(this.range[0]);
      let end = moment(this.range[1]);
      const dates = [];
      while (!begin.isAfter(end)) {
        dates.push(begin.format("YYYY-MM-DD"));
        begin = begin.add(1, "days");
      }
      const params = {
        criteria: { biz: this.biz._id, date: { $in: dates } },
      };
      const url = "sales_daily_report/list";
      this.statements = await this.$api.biz.custom(url, params);
    },
  },
};
</script>
