<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import moment from "moment-timezone";
import _ from "underscore";
import { mapGetters } from "vuex";
import CSV from "/libs/utils/CSV";

export default {
  props: {
    range: { type: Array, required: true },
    items: { type: Array, required: true },
  },
  computed: {
    ...mapGetters(["biz"]),
    dates() {
      return this.range.map((o) => moment(o).format("ddd L")).join(" ~ ");
    },
    product_mix() {
      return this.items.flatMap((o) => o.product_mix);
    },
    courseGroups() {
      const courses = this.biz.courses;
      return _.chain(this.product_mix.flatMap((item) => item.courses))
        .groupBy("id")
        .map((o) => {
          const quantity = o.reduce((a, b) => a + b.quantity, 0);
          const amount = o.reduce((a, b) => a + b.amount, 0);
          const last = o[o.length - 1];
          const course = courses.find((o) => o._id === last.id);
          return {
            _id: last.id,
            name: last.name,
            quantity,
            amount,
            group: course?.grp || "Other",
          };
        })
        .groupBy("group")
        .value();
    },
    total() {
      return this.items?.reduce((a, b) => a + b.sales.total, 0);
    },
    net() {
      return this.items?.reduce((a, b) => a + b.sales.net, 0);
    },
    tax() {
      return this.items?.reduce((a, b) => a + b.sales.tax, 0);
    },
    tip() {
      return this.items?.reduce((a, b) => a + b.sales.tip, 0);
    },
    fee() {
      return this.items?.reduce((a, b) => a + b.sales.fee, 0);
    },
    third_party() {
      return this.items?.reduce((a, b) => a + b.sales.third_party, 0);
    },
    gift() {
      return this.items?.reduce((a, b) => a + b.sales.gift, 0);
    },
    adjustment() {
      return this.items?.reduce((a, b) => a + b.sales.adjustment, 0);
    },
    deduction() {
      return this.items?.reduce((a, b) => a + b.sales.deduction, 0);
    },
    selflane() {
      return this.items?.reduce((a, b) => a + b.sales.selflane, 0);
    },
    charges() {
      const items = this.items?.flatMap((o) => o.charge.items) || [];
      return _.chain(items)
        .groupBy("method")
        .map((value, key) => {
          const method = key;
          const title = value[0].title;
          const amount = value.reduce((a, b) => a + b.amount, 0);
          return { method, title, amount };
        })
        .sortBy("title")
        .value();
    },
    totalCharge() {
      return this.items?.reduce((a, b) => a + b.charge.total, 0);
    },
    open() {
      return this.items?.reduce((a, b) => a + b.orders.open, 0);
    },
  },
  methods: {
    download() {
      if (!this.biz?._id) return;
      const currency = this.$options.filters.currency;

      const csv = new CSV();
      csv.addRow(["Date", this.dates]);

      _.each(this.courseGroups, (group, key) => {
        let total = group.reduce((acc, cur) => acc + cur.amount, 0);
        group.forEach((item) => addLine("", item.name, item.amount));
        addLine(key, "", total);
        csv.addNewRow();
      });
      csv.addNewRow();
      addLine("", "Net Sales", this.net);
      addLine("", "Tax", this.tax);
      addLine("", "Tips", this.tip);
      addLine("", "Gift Card Sold", this.gift);
      addLine("", "Restaurant Fee", this.fee);
      addLine("", "Third Party", this.third_party);
      addLine("", "Cash Discount Adj.", this.adjustment);
      addLine("", "Deduction", this.deduction);
      addLine("", "Selflane Charges", this.selflane);
      addLine("Total Receivables", "", this.total);
      csv.addNewRow();
      this.charges.forEach((charge) => {
        addLine("", charge.title, charge.amount);
      });
      addLine("Total Received", "", this.totalCharge);
      csv.addNewRow();
      csv.addNewRow();
      if (this.open > 0) {
        const note = `There are open orders in this period. The total collected might not be balanced with the total sales.`;
        csv.addRow(["Note", "", note]);
      }
      csv.save("sales");

      function addLine(title, detail, amount) {
        if (amount == 0) return;
        csv.addRow([title, detail, currency(amount)]);
      }
    },
  },
};
</script>
