<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Product Mix Report
    v-spacer
    v-btn-toggle(v-model="tab", color="secondary", dense)
      v-btn.text-capitalize(
        small,
        v-for="(item, index) in tab_items",
        :key="index",
        :value="item"
      ) {{ item }}
  Item(:dateRange="dateRange", v-if="tab == 'dish'", :dishes="dishes")
  Course(:dateRange="dateRange", v-if="tab == 'course'", :courses="courses")
  Menu(:dateRange="dateRange", v-if="tab == 'menu'", :menus="menus")
  Staff(:dateRange="dateRange", v-if="tab == 'staff'", :dishes="dishes")
</template>

<script>
import { mapGetters } from "vuex";
import Course from "./Course";
import Item from "./Item";
import Menu from "./Menu";
import Staff from "./Staff";

export default {
  name: "SaleByCate",
  components: { Item, Staff, Course, Menu },
  props: {
    statements: { type: Array, required: true },
    range: { type: Array, required: true }, // [YYYY-MM-DD, YYYY-MM-DD]
  },
  data() {
    return {
      tab: "dish",
      tab_items: ["dish", "course", "menu", "staff"],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    product_mix() {
      return this.statements.flatMap((statement) => statement.product_mix);
    },
    dishes() {
      return this.product_mix.flatMap((item) => item.dishes);
    },
    courses() {
      return this.product_mix.flatMap((item) => item.courses);
    },
    menus() {
      return this.product_mix.flatMap((item) => item.menus);
    },
    dateRange() {
      return this.range.join(" - ");
    },
  },
};
</script>
