<template lang="pug">
v-data-table(
  :headers="headers",
  :items="items",
  :search="search",
  dense,
  mobile-breakpoint="0"
)
  template(v-slot:top)
    .d-flex.flex-row.align-center.px-4
      v-text-field.mr-4(v-model="search", label="Search", clearable)
      CSVDownload(:items="csvData", file-name="product_mix_by_staff")
  template(v-slot:item.amount="{ item }")
    span {{ item.amount | currency }}
</template>

<script>
import CSVDownload from "@/components/basic/CSVDownload";
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  components: { CSVDownload },
  props: {
    dateRange: { type: String, required: true },
    dishes: { type: Array, required: true },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Quantity", value: "quantity" },
        { text: "Total", value: "amount" },
        { text: "Staff", value: "staff_name" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    items() {
      const data = _.chain(this.dishes)
        .groupBy((o) => {
          if (o.staff_id) {
            return o.staff_id + o.id;
          } else {
            return o.id + o.staff_name;
          }
        })
        .map((o) => {
          const quantity = o.reduce((a, b) => a + b.quantity, 0);
          const amount = o.reduce((a, b) => a + b.amount, 0);
          const last = o[o.length - 1];
          return {
            _id: last.id,
            name: last.name,
            quantity: quantity,
            amount: amount,
            staff_id: last.staff_id,
            staff_name: last.staff_name,
          };
        })
        .sortBy((o) => -o.amount)
        .sortBy((o) => o.staff_name)
        .value();
      return data;
    },
    csvData() {
      if (!this.items || !this.items.length || !this.biz) return [];
      const currency = this.$options.filters.currency;
      let data = [this.headers.map((o) => o.text)];
      this.items.forEach((item) => {
        data.push([
          item.name,
          item.quantity,
          currency(item.amount),
          item.staff_name,
        ]);
      });
      data.push([]); // spacing row
      data.push([this.dateRange]);
      data.push([this.biz.name]);
      return data;
    },
  },
  methods: {
    getMenuName(val) {
      return this.biz.menus.find((o) => o._id == val)?.name || "N/A";
    },
    getCourseName(val) {
      return this.biz.courses.find((o) => o._id == val)?.name || "N/A";
    },
  },
};
</script>
